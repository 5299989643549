.weekday-select {
    width: 100%;
    .weekday {
        display: inline-block;
        cursor: pointer;
    
        width: calc(100%/7 - (6*5px)/7);
        padding: 3px 0;
        margin-right: 5px;
        &:last-child {
            margin-right: 0px;
        }

        text-align: center;

        background-color: #f5f5f5;

        &.active {
            background-color: #e9e9e9;
            outline: #e9e9e9 solid 2px;
        }
    }
}