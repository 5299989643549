.list {
    padding: 12.5px 0;
    height: calc(750px*0.625);
    max-height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    .entry-container {
        position: relative;
        
        .list-entry {
            position: relative;
            z-index: 1;
        }

        .entry-settings {
            position: absolute;
            z-index: 0;

            right: 0;
            top: 0;

            width: 37.5%;
            height: 100%;

            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            .entry-setting {
                cursor: pointer;

                width: 47.5%;
                height: 100%;

                display: flex;
                vertical-align: middle;

                border-radius: 5px;

                &.success {
                    background-color: #DFF2BF;
                    color: #4F8A10;
                }

                &.info {
                    background-color: #d9edf7;
                    color: #31708f;
                }

                position: relative;
                i {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        &.active {
            .list-entry {
                animation-name: slide-left;
                animation-duration: 200ms;
                transform: translateX(-40%);
            }

            @keyframes slide-left {
                from {
                    transform: translate(0);
                }
                to {
                    transform: translate(-40%);
                }
            }
        }
        &.inactive {
            .list-entry {
                animation-name: slide-right;
                animation-duration: 200ms;
                transform: translateX(0);
            }

            @keyframes slide-right {
                from {
                    transform: translate(-40%);
                }
                to {
                    transform: translate(0);
                }
            }
        }
    }

    .list-entry {
        cursor: pointer;
        
        margin: 12.5px 0;
        padding: 15px 25px;

        background-color: #fff;

        border-radius: 5px;

        .title {
            font-weight: 600;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;           
        }

        .tag {
            font-size: 12px;
            font-weight: 400;
            padding: 2.5px 15px;

            border-radius: 5px;

            color: white;
        }

        &.todo {
            .content {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                margin-top: 5px;

                .information-area {
                    display: flex;
                    flex-direction: column;

                    font-size: 14px;

                    color: #6C6C6C;

                    i {
                        display: inline-block;
                        width: 20px;
                    }
                }
            }
        }

        &.calendar {
            display: grid;
            grid-template-columns: 15% 85%;

            font-size: 10px;

            .time {
                padding-right: 9px;

                border-right: 1px solid #707070;
                display: flex;
                flex-direction: column;
                justify-content: center;
                
                .from, .to {
                    display: block;
                    color: #707070;
                }
            }
            .content {
                padding-left: 9px;

                .title, .lecturer, .room {
                    display: block;
                    i {
                        padding-right: 9px;
                    }
                }

                .title {
                    font-size: 14px;
                    padding-bottom: 4.5px;
                }

                .lecturer, .room {
                    color: #707070;
                }
            }
        }
    }
} 