ul.bottom-navigation {
    position: absolute;
    bottom: 0px;
    left: 0px;

    width: 100%;
    height: 45px;
    background: white;

    display: flex;
    justify-content: center;

    .nav-element {
        width: 100%;
        height: 100%;

        display: flex;

        list-style-type: none;

        cursor: pointer;

        a {
            width: 100%;
            height: 100%;

            text-decoration: none;
            
            position: relative;

            i {
                position: absolute;

                top: 50%;
                left: 50%;

                transform: translate(-50%, -55%);

                font-size: 18px;
                
                &.bi-list-check {
                    font-size: 20px;
                }
            }

            color: #696969;
        }

        &.active, &:active, &:focus {
            background: #F7F7F7;
            color: black;
        }
    }
}