.modal-container {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    z-index: 99999;

    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    @media only screen and (min-width: 480px) {
        top: 15px;
        width: 360px;
        height: 750px;
    }
    overflow: hidden;

    &.hide-modal {
        &.darken {
            animation-duration: 500ms;
            animation-name: darken-bg-reverse;
    
            background-color: rgba(0, 0, 0, 0.3);
        }

        &.ligthen {
            animation-duration: 500ms;
            animation-name: lighten-bg-reverse;
    
            background-color: rgba(255, 255, 255, 0.3);
        }

        .modal {
            animation-duration: 250ms;
            animation-name: slide-in-bottom-reverse;

            position: absolute;
            bottom: -100%;
        }
    }

    &.ligthen {
        animation-duration: 250ms;
        animation-name: lighten-bg;

        background-color: rgba(255, 255, 255, 0.3);
    }

    &.darken {
        animation-duration: 250ms;
        animation-name: darken-bg;

        background-color: rgba(0, 0, 0, 0.3);
    }

    .modal-background {
        position: absolute;
        bottom: 0;
        left: 0;

        width: inherit;
        height: inherit;

        z-index: 999991;
    }

    .loading-spinner {
        position: absolute;
        z-index: 9999999;
        
        bottom: 0;
        left: 0;

        height: 100%;
        width: 100%;

        border-radius: 20px 20px 0 0;

        background-color: rgba(255, 255, 255, 0.39);

        &:before {
            content: '';
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 40px;
            margin-top: -15px;
            margin-left: -15px;
            border-radius: 50%;
            border: 1px solid #ccc;
            border-top-color: rgb(221, 0, 29);
            animation: spinner .6s linear infinite;
            @keyframes spinner {
                to {transform: rotate(360deg);}
            }
        }
    }

    .modal {
        position: absolute;
        bottom: 0;
        left: 0;

        z-index: 999999;

        width: inherit;
        height: fit-content;

        padding: 0 18px;

        background-color: white;

        border-radius: 20px 20px 0 0;

        animation-duration: 500ms;
        animation-name: slide-in-bottom;

        .modal-header {
            padding-top: 36px;

            .modal-close-icon {
                cursor: pointer;
                position: absolute;
                top: 36px;
                right: 24px;

                font-size: 28px;

                color: #111;
                &:hover, &:active {
                    color: #000;
                }
            }

            h1.modal-title {
                font-size: 21px;
                font-weight: 600;
            }
    
            p.modal-description {
                font-size: 14px;
                color: #6c6c6c;
            }
        }
        
        .modal-body {
            padding: 18px 0 54px 0;

            .btn {
                margin-top: 18px;
                &.w-100 {
                    width: calc(100% - 2*18px);
                }
            }
        }

    }
}

@keyframes slide-in-bottom {
    from {
      bottom: -100%;
    }
  
    to {
      bottom: 0;
    }
}

@keyframes slide-in-bottom-reverse {
    from {
      bottom: 0;
    }

    to {
        bottom: -100%;
      }
}

@keyframes darken-bg {
    from {
        background-color: rgba(0, 0, 0, 0);
    }

    to {
        background-color: rgba(0, 0, 0, 0.3);
    }
}
@keyframes darken-bg-reverse {
    from {
        background-color: rgba(0, 0, 0, 0.3);
    }

    to {
        background-color: rgba(0, 0, 0, 0);
    }
}

@keyframes lighten-bg {
    from {
        background-color: rgba(255, 255, 255, 0);
    }

    to {
        background-color: rgba(255, 255, 255, 0.3);
    }
}

@keyframes lighten-bg-reverse {
    from {
        background-color: rgba(255, 255, 255, 0.3);
    }

    to {
        background-color: rgba(255, 255, 255, 0);
    }
}