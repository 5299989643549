.btn-row {
    width: 100%;

    &.bottom {
        position: absolute;
        bottom: 45px + 36px;
    }

    margin-top: 25px;
    &.no-margin {
        margin-top: 0;
    }

    display: flex;
    justify-content: space-between;

    &.btn-fit-2 {
        .btn {
            width: 47.5%;
            &:first-child {
                margin-right: 2.5%;
            }
            &:last-child {
                margin-left: 2.5%;
            }
        }
    }  
}

button, input[type="button"], input[type="submit"], input[type="reset"] {
    all: unset;

    &.btn {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: inherit;
        
        text-align: center;
        padding: 9px 18px;
        border-radius: 5px;

        font-size: 14px;

        &.w-100 {
            width: calc(100% - 2*18px);
        }
        
        &-primary {
            background-color: #FF1744;
            color: #FFF;
            &:active, &:focus {
                outline: 1px solid #ff17455b;
            }
        }

        &-secondary {
            background-color: #FFF;
            color: #000;
            &:active, &:focus {
                outline: 1px solid #00000011;
            }
            &-dark {
                background-color: #e5e5e5;
            }
        }

        &-grey {
            background-color: #302f2f;
            color: #FFF;
            &:active, &:focus {
                outline: 1px solid #00000011;
            }
        }

        &-lg {
            min-width: 250px;
            padding: 12px 18px;
        }

        &-sm {
            padding: 6px 18px;
        }

        &.inactive {
            cursor: initial;
            
            background-color: #c2c2c2;
            color: rgb(97, 97, 97);
            &:active, &:focus {
                outline: none;
            }
        }

    }
    
}