.w-100 {
  width: 100%;
}

.page-container {
  padding: 25px 25px;
}

h1 {
  font-size: 21px;
  font-weight: 600;
}

.subheadline, .modal-description {
  font-size: 14px;
  color: #6c6c6c;
}

.highlighted {
  color: black;
}

.loading-spinner {
  position: absolute;
  z-index: 9999999999999999999;
  
  bottom: 0;
  left: 0;

  height: 100%;
  width: 100%;

  background-color: #212121;
}

.loading-spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 1px solid #ccc;
  border-top-color: rgb(221, 0, 29);
  animation: spinner .6s linear infinite;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}