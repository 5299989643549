.alert {
    width: 100%;
    padding: 6px 18px 7px 18px;
    font-size: 14px;
    border-radius: 5px;

    &-danger {
        background: #FFBABA;
        color: #D8000C;
    }

    &-warning {
        background: #e6c47b;
        border: 1px solid rgb(211, 160, 20);
    }
}