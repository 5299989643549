h3 {
    margin-bottom: 12px;
}

.items {
    cursor: pointer;
    margin-bottom: 24px;
    .item {
        display: inline-block;
        padding: 3px 15px;
        margin-right: 2.5px;
        margin-bottom: 5px;

        font-size: 14px;

        border: #e3e3e3 2px solid;
        border-radius: 5px;

        background-color: #fff;
    }
}

.selectable-color {
    cursor: pointer;

    display: inline-block;
    height: 30px;
    width: 45px;

    margin-right: 5px;

    border-radius: 5px;

    &.selected {
        outline: 2px solid #3c3c3cbe;
        outline-offset: 1px;
    }
}