.onboarding {
    position: absolute;
    top: -18px;
    bottom: 0;
    z-index: 99999999999999999;

    width: 100%;
    height: 100%;

    background-color: white;

    padding: 50px 25px 0 25px;

    p {
        font-size: 14px;
        color: #444;
    }

    .btns {
        position: absolute;

        width: calc(100% - 50px);

        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);

        .btn-text {
            color: #555;
        }
    }
}