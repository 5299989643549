.input-spacer {
    display: inline-block;
    width: 10%;
} 

.input-group {
    margin: 18px 0px;

    &.w-50 {
        display: inline-block;
        width: 45%;
    } 

    label.input-title {
        display:inline-block;
        margin-bottom: 9px;

        font-size: 14px;
        font-weight: 600;
    }

    input.input-field, select.select-field {
        background: none;
        color: inherit;
        border: none;
        cursor: pointer;
        outline: inherit;

        padding: 9px 18px;

        background-color: #EAEAEA;

        &.valid {
            border-color: rgb(182, 218, 182);
        }
        &.invalid {
            border-color: rgb(221, 173, 173);
        }

        &:active, &:focus {
            outline: 2px solid #3c3c3c67;
        }

        &[type="time"] {
            text-align: center;
        }
    }

    select.select-field {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: calc(100% - 16px);
        background-position-y: 50%;
        border-radius: 2px;
    }
}