#root {
    background-color: #212121;
}

.auth-page {
    position: relative;
    .container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        display: flex;
        flex-direction: column;
        align-items: center;
    
        .btn-container {
            margin-top: 72px;

            display: flex;
            flex-direction: column;
            align-items: center;

            .btn:first-child {
                margin-bottom: 18px;
            }
        }
    }
}