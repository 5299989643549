.modal-container {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    z-index: 9999999999999;

    width: 100vw;
    height: 100vh;
    max-height: -webkit-fill-available;
    @media only screen and (min-width: 480px) {
        top: 15px;
        width: 360px;
        height: 750px;
    }
    overflow: hidden;

    &.hide-modal {
        &.darken {
            animation-duration: 500ms;
            animation-name: darken-bg-reverse;
    
            background-color: rgba(0, 0, 0, 0.3);
        }

        &.ligthen {
            animation-duration: 500ms;
            animation-name: lighten-bg-reverse;
    
            background-color: rgba(255, 255, 255, 0.3);
        }

        .msg-box-modal {
            animation-duration: 250ms;
            animation-name: slide-in-top-reverse;

            position: absolute;
            top: -100%;
        }
    }

    &.ligthen {
        animation-duration: 250ms;
        animation-name: lighten-bg;

        background-color: rgba(255, 255, 255, 0.3);
    }

    &.darken {
        animation-duration: 250ms;
        animation-name: darken-bg;

        background-color: rgba(0, 0, 0, 0.3);
    }

    .msg-box-modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        z-index: 99999999999999;

        width: 95%;
        height: fit-content;

        padding: 0 18px;

        background-color: white;

        border-radius: 10px;

        animation-duration: 500ms;
        animation-name: slide-in-top;

        .modal-header {
            padding-top: 36px;

            .modal-close-icon {
                cursor: pointer;
                position: absolute;
                top: 36px;
                right: 24px;

                font-size: 28px;

                color: #111;
                &:hover, &:active {
                    color: #000;
                }
            }
        }
        
        .modal-body {
            padding: 18px 0 54px 0;

            .btn {
                margin-top: 18px;

                &-secondary {
                    background-color: #f3f3f3;
                }
            }
        }

    }
}

@keyframes slide-in-top {
    from {
      top: -100%;
    }
  
    to {
      top: 50%;
    }
}

@keyframes slide-in-top-reverse {
    from {
      top: 50%;
    }

    to {
      top: -100%;
    }
}

@keyframes darken-bg {
    from {
        background-color: rgba(0, 0, 0, 0);
    }

    to {
        background-color: rgba(0, 0, 0, 0.3);
    }
}
@keyframes darken-bg-reverse {
    from {
        background-color: rgba(0, 0, 0, 0.3);
    }

    to {
        background-color: rgba(0, 0, 0, 0);
    }
}

@keyframes lighten-bg {
    from {
        background-color: rgba(255, 255, 255, 0);
    }

    to {
        background-color: rgba(255, 255, 255, 0.3);
    }
}

@keyframes lighten-bg-reverse {
    from {
        background-color: rgba(255, 255, 255, 0.3);
    }

    to {
        background-color: rgba(255, 255, 255, 0);
    }
}